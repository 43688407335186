import { ApolloProvider } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import * as React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { apolloClient } from "../utils/apollo-client";
import { deleteToken, setToken } from "../utils/auth";
import Loading from "./Loading";
// import Login from './Login'
import PrivateRoute from "./PrivateRoute";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
// Containers
const TheLayout = React.lazy(() => import("../containers/TheLayout"));

const AppRouter = () => {
  const { initialized, keycloak } = useKeycloak();

  React.useEffect(() => {
    const token = keycloak?.token;
    if (token) {
      setToken(token);
    } else {
      deleteToken();
    }
  }, [keycloak?.token]);
  if (!initialized) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          {/* <Route path="/login" component={Login} exact={true} /> */}
          <ApolloProvider client={apolloClient}>
            <PrivateRoute path="/" component={TheLayout} />
          </ApolloProvider>
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
