import Keycloak from "keycloak-js";
// require("dotenv").config();

// We changed the Keycloak instante and it requires also the latest keycloak-js,
// but here it does not work so we keep the version that works.
export const keycloak = Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_AUTH_URL || "https://auth.bibeltv.de/auth/",
  realm: process.env.REACT_APP_KEYCLOAK_REALM || "Videohub",
  // 'cockpit','cockpit_dev','cockpit_staging'
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT || "cockpit",
});

//login-required or check-sso
export const keycloakProviderInitConfig: Keycloak.KeycloakInitOptions = {
  onLoad: "check-sso",
  checkLoginIframe: false,
  // checkLoginIframeInterval: 3600
};

export const setToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const deleteToken = () => {
  localStorage.removeItem("token");
};

export const getToken = () => {
  return localStorage.getItem("token");
};
