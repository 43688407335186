import { CSpinner } from "@coreui/react";
import React from "react";
interface Props {
  position?: "fixed" | "absolute" | "relative";
}

export default function Loading(props: Props) {
  return (
    <CSpinner
      style={{
        position: props.position || "fixed",
        width: "10rem",
        height: "10rem",
        zIndex: 666,
        top: "50%",
        left: "50%",
        marginTop: " -5em",
        marginLeft: "-5em ",
      }}
      color="primary"
    />
  );
}
