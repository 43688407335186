import { positions, transitions } from "react-alert";

export const ServerRoute = `${
  process.env.NODE_ENV === "development" ? "http://localhost:5000" : ""
}/api/`;

export const imageUrlPrefix = "https://btv-web-assets.imgix.net/mediathek/";
export const imageUrlStrato = "https://web-assets.bibeltv.de/mediathek/";

export const alertConfig = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  containerStyle: { zIndex: 6666 },
  transition: transitions.FADE,
};
