import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Component } from "react";
import { Provider as AlertProvider } from "react-alert";
//@ts-ignore
import AlertTemplate from "react-alert-template-basic";
import AppRouter from "./components/AppRouter";
import "./scss/style.scss";
import { keycloak, keycloakProviderInitConfig } from "./utils/auth";
import { alertConfig } from "./utils/config";

// Cockpit admin ui

class App extends Component {
  render() {
    return (
      <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitConfig}>
        <AlertProvider template={AlertTemplate} {...alertConfig}>
          <AppRouter />
        </AlertProvider>
      </ReactKeycloakProvider>
    );
  }
}

export default App;
