export const logoNegative = ['690 134', `
  <title>Bibel TV Negative</title>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1172 302.8" style="enable-background:new 0 0 1275.59 425.2;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
	.st1{fill:#FFFFFF;}
	.st2{fill:#FABE00;}
	.st3{fill:#F5ED00;}
</style>
<g>
	<g>
		<path class="st0" d="M318.5,293.84h40.62v-19.86h0.6c9.32,17.15,28.28,24.08,51.15,24.08c30.98,0,64.37-24.97,64.37-82.14
			c0-56.87-33.39-81.84-64.37-81.84c-19.26,0-38.52,7.22-49.05,23.17h-0.6V79.03H318.5V293.84L318.5,293.84z M432.53,216.23
			c0,25.88-11.14,49.63-36.4,49.63c-25.28,0-36.4-23.76-36.4-49.63c0-26.18,11.13-49.94,36.4-49.94
			C421.39,166.28,432.53,190.05,432.53,216.23L432.53,216.23z"/>
		<path class="st0" d="M485.45,293.84h42.73L528.15,140h-42.72L485.45,293.84L485.45,293.84z M528.18,79.03h-42.73v35.2h42.73V79.03
			L528.18,79.03z"/>
		<path class="st0" d="M546.78,293.84h40.62v-19.86h0.6c9.32,17.15,28.28,24.08,51.14,24.08c30.98,0,64.38-24.97,64.38-82.14
			c0-56.87-33.4-81.84-64.38-81.84c-19.25,0-38.5,7.22-49.04,23.17h-0.6V79.03h-42.72V293.84L546.78,293.84z M660.81,216.23
			c0,25.88-11.14,49.63-36.41,49.63c-25.28,0-36.4-23.76-36.4-49.63c0-26.18,11.12-49.94,36.4-49.94
			C649.67,166.28,660.81,190.05,660.81,216.23L660.81,216.23z"/>
		<path class="st0" d="M862.95,226.45c3.01-47.83-22.56-92.36-75.51-92.36c-47.24,0-79.42,35.51-79.42,82.14
			c0,48.13,30.39,81.84,79.42,81.84c35.19,0,60.77-15.66,72.8-52.36h-37.61c-2.7,9.63-16.53,20.15-33.7,20.15
			c-23.76,0-36.99-12.33-38.2-39.41H862.95L862.95,226.45z M750.74,199.38c0.61-12.03,8.43-33.09,35.5-33.09
			c20.77,0,30.1,11.43,34.01,33.09H750.74L750.74,199.38z"/>
		<polygon class="st1" points="871.38,293.84 914.11,293.84 914.11,79.03 871.38,79.03 871.38,293.84 		"/>
		<polygon class="st2" points="1093.8,139.88 961.83,139.88 961.83,174.38 1007.77,174.38 1007.77,293.84 1047.88,293.84 
			1047.88,174.38 1093.8,174.38 1093.8,139.88 		"/>
		<polygon class="st2" points="1213.93,139.88 1172.09,139.88 1141.9,254.38 1141.48,254.38 1111.29,139.88 1069.45,139.88 
			1117.97,293.84 1164.11,293.84 1213.93,139.88 		"/>
		<path class="st1" d="M937.51,270.74c0-12.77,10.36-23.13,23.13-23.13c12.76,0,23.11,10.36,23.11,23.13
			c0,12.76-10.34,23.11-23.11,23.11C947.87,293.84,937.51,283.5,937.51,270.74L937.51,270.74z"/>
	</g>
	<g id="Stern">
		<g>
			<polygon class="st2" points="179.99,178.27 195.93,187.98 265.13,146.25 265.13,127.04 			"/>
		</g>
		<g>
			<g>
				<polygon class="st3" points="163.46,207.52 163.46,301.95 179.99,293.86 179.99,217.47 				"/>
				<polygon class="st2" points="163.46,188.21 77.38,240 77.38,259.33 146.93,217.47 146.93,293.86 163.46,301.95 163.46,207.52 
					249.55,259.33 249.55,240 				"/>
			</g>
			<g>
				<g>
					<polygon class="st3" points="146.93,158.8 146.93,158.79 77.22,116.86 61.79,127.04 146.93,178.23 					"/>
				</g>
			</g>
		</g>
		<polygon class="st3" points="265.26,230.18 179.99,178.27 265.13,127.04 249.7,116.86 179.99,158.79 179.99,79.03 163.46,69.39 
			146.93,79.03 163.46,87.48 163.46,188.23 249.53,240.01 249.55,240.01 249.55,259.33 265.26,249.21 265.26,230.18 		"/>
		<polygon class="st2" points="146.93,79.03 146.93,178.23 163.45,188.22 163.46,188.23 163.46,87.48 		"/>
		<polygon class="st3" points="163.46,188.23 146.92,178.22 61.66,230.18 61.66,230.18 61.66,249.21 77.38,259.33 77.38,240.01 
			77.39,240.01 		"/>
		<polygon class="st2" points="146.92,178.22 61.79,127.04 61.79,146.25 130.94,187.95 		"/>
	</g>
</g>
</svg>
`]
