import {
  cilAccountLogout,
  cilApplications,
  cilApplicationsSettings,
  cilAudio,
  cilBan,
  cilBook,
  cilCamera,
  cilCloudUpload,
  cilCopy,
  cilFile,
  cilFilter,
  cilHandPointLeft,
  cilInput,
  cilLibrary,
  cilList,
  cilLockLocked,
  cilLockUnlocked,
  cilMediaPlay,
  cilMediaStepBackward,
  cilMoon,
  cilMove,
  cilPencil,
  cilPlaylistAdd,
  cilPlus,
  cilReload,
  cilSave,
  cilSpeedometer,
  cilSpreadsheet,
  cilSun,
  cilTags,
  cilTrash,
  cilUserFemale,
  cilVideo,
  cilWindow,
} from "@coreui/icons/js/free";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";
import { sygnet } from "./sygnet";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilApplicationsSettings,
    cilMediaStepBackward,
    cilList,
    cilPencil,
    cilCloudUpload,
    cilBan,
    cilFile,
    cilMove,
    cilLibrary,
    cilTrash,
    cilCopy,
    cilLockLocked,
    cilBook,
    cilReload,
    cilLockUnlocked,
    cilUserFemale,
    cilAccountLogout,
    cilSave,
    cilPlus,
    cilPlaylistAdd,
    cilHandPointLeft,
    cilWindow,
    cilSpeedometer,
    cilCamera,
    cilFilter,
    cilSun,
    cilVideo,
    cilAudio,
    cilMoon,
    cilMediaPlay,
    cilSpreadsheet,
    cilTags,
    cilInput,
    cilApplications,
  }
);
