export const logo = ['690 134', `
  <title>Bibel TV</title>
  <svg version="1.1" id="Ebene_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 1172 302.8" style="enable-background:new 0 0 1172 302.8;" xml:space="preserve">
  <style type="text/css">
    .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#004377;}
    .st1{fill:#004377;}
    .st2{fill:#FABE00;}
    .st3{fill:#F5ED00;}
  </style>
  <g id="BibelTV_Logo_2017">
    <g id="Wortmarke">
      <path class="st0" d="M268.5,260.5h40.6v-19.9h0.6c9.3,17.2,28.3,24.1,51.2,24.1c31,0,64.4-25,64.4-82.1
        c0-56.9-33.4-81.8-64.4-81.8c-19.3,0-38.5,7.2-49,23.2h-0.6V45.7h-42.7V260.5L268.5,260.5z M382.5,182.9
        c0,25.9-11.1,49.6-36.4,49.6c-25.3,0-36.4-23.8-36.4-49.6c0-26.2,11.1-49.9,36.4-49.9C371.4,132.9,382.5,156.7,382.5,182.9
        L382.5,182.9z"/>
      <path class="st0" d="M435.5,260.5h42.7l0-153.8h-42.7L435.5,260.5L435.5,260.5z M478.2,45.7h-42.7v35.2h42.7V45.7L478.2,45.7z"/>
      <path class="st0" d="M496.8,260.5h40.6v-19.9h0.6c9.3,17.2,28.3,24.1,51.1,24.1c31,0,64.4-25,64.4-82.1
        c0-56.9-33.4-81.8-64.4-81.8c-19.3,0-38.5,7.2-49,23.2h-0.6V45.7h-42.7V260.5L496.8,260.5z M610.8,182.9
        c0,25.9-11.1,49.6-36.4,49.6c-25.3,0-36.4-23.8-36.4-49.6c0-26.2,11.1-49.9,36.4-49.9C599.7,132.9,610.8,156.7,610.8,182.9
        L610.8,182.9z"/>
      <path class="st0" d="M813,193.1c3-47.8-22.6-92.4-75.5-92.4c-47.2,0-79.4,35.5-79.4,82.1c0,48.1,30.4,81.8,79.4,81.8
        c35.2,0,60.8-15.7,72.8-52.4h-37.6c-2.7,9.6-16.5,20.2-33.7,20.2c-23.8,0-37-12.3-38.2-39.4H813L813,193.1z M700.7,166
        c0.6-12,8.4-33.1,35.5-33.1c20.8,0,30.1,11.4,34,33.1H700.7L700.7,166z"/>
      <polygon class="st1" points="821.4,260.5 864.1,260.5 864.1,45.7 821.4,45.7 821.4,260.5 		"/>
      <polygon class="st2" points="1043.8,106.5 911.8,106.5 911.8,141 957.8,141 957.8,260.5 997.9,260.5 997.9,141 1043.8,141 
        1043.8,106.5 		"/>
      <polygon class="st2" points="1163.9,106.5 1122.1,106.5 1091.9,221 1091.5,221 1061.3,106.5 1019.4,106.5 1068,260.5 
        1114.1,260.5 1163.9,106.5 		"/>
      <path class="st1" d="M887.5,237.4c0-12.8,10.4-23.1,23.1-23.1c12.8,0,23.1,10.4,23.1,23.1c0,12.8-10.3,23.1-23.1,23.1
        C897.9,260.5,887.5,250.1,887.5,237.4L887.5,237.4z"/>
    </g>
    <g id="Stern">
      <g>
        <polygon class="st2" points="130,144.9 145.9,154.6 215.1,112.9 215.1,93.7 			"/>
      </g>
      <g>
        <g>
          <polygon class="st3" points="113.5,174.2 113.5,268.6 130,260.5 130,184.1 				"/>
          <polygon class="st2" points="113.5,154.8 27.4,206.6 27.4,226 96.9,184.1 96.9,260.5 113.5,268.6 113.5,174.2 199.5,226 
            199.5,206.6 				"/>
        </g>
        <g>
          <g>
            <polygon class="st3" points="96.9,125.4 96.9,125.4 27.2,83.5 11.8,93.7 96.9,144.9 					"/>
            <path class="st2" d="M113.5,154.9"/>
          </g>
        </g>
      </g>
      <polygon class="st3" points="215.3,196.8 130,144.9 215.1,93.7 199.7,83.5 130,125.4 130,45.7 113.5,36 96.9,45.7 113.5,54.1 
        113.5,154.9 199.5,206.6 199.5,206.6 199.5,226 215.3,215.8 215.3,196.8 		"/>
      <polygon class="st2" points="96.9,45.7 96.9,144.9 113.5,154.9 113.5,154.9 113.5,54.1 		"/>
      <polygon class="st3" points="113.5,154.9 96.9,144.9 11.7,196.8 11.7,196.8 11.7,215.8 27.4,226 27.4,206.6 27.4,206.6 		"/>
      <polygon class="st2" points="96.9,144.9 11.8,93.7 11.8,112.9 80.9,154.6 		"/>
    </g>
  </g>
  </svg>
  
`]
