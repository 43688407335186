import { useKeycloak } from "@react-keycloak/web";
import * as React from "react";
import { Route, RouteProps } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
interface PrivateRouteProps extends RouteProps {}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const { component, ...other } = props;
  const Component: any = component;
  const { keycloak } = useKeycloak();

  return (
    <QueryParamProvider adapter={ReactRouter5Adapter}>
      <Route
        {...other}
        render={(props) => {
          // console.log(keycloak);
          return keycloak?.authenticated ? <Component {...props} /> : keycloak?.login();
        }}
      />
    </QueryParamProvider>
  );
};

export default PrivateRoute;
